import { navigateToUrl, registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { SubscriptionResponse } from "./models/suscription";
import { WorkFlowModel } from "./models/workflowModel";
import * as FlowEngine from "./services/flowEngine";

console.log("Inicio V060924:222");
const loader = document.getElementById("loader");
start();

const txStatus = await FlowEngine.getTxStatus();
let flowReq, subReq, flowReqEroor, subReqEroor;
if (typeof txStatus !== "string") {
  flowReq = txStatus.find((x) => x.admStatus.description === "Request flujo");
  subReq = txStatus.find(
    (x) => x.admStatus.description === "Request suscripción"
  );
  flowReqEroor = txStatus.find(
    (x) => x.admStatus.description === "Error al cargar flujo"
  );
  subReqEroor = txStatus.find(
    (x) => x.admStatus.description === "Error al cargar suscripción"
  );
}
let flow: WorkFlowModel;
const idFlow = FlowEngine.getQueryParam("idFlow");
const idSubscription = FlowEngine.getQueryParam("suscription");
const idTx = FlowEngine.getQueryParam("txId");
const email = FlowEngine.getQueryParam("email");
const phone =
  FlowEngine.getQueryParam("phone") !== null &&
  FlowEngine.getQueryParam("phone") !== undefined
    ? FlowEngine.getQueryParam("phone")
    : "";

const flowResponse = await FlowEngine.getFlowBySubscription(idSubscription); //await FlowEngine.getFlow(idFlow);
if (typeof flowResponse !== "string") {
  flow = flowResponse as WorkFlowModel;
}
var steps = (flow as WorkFlowModel).workflowSteps;
const theme = (flow as WorkFlowModel).theme;
document.documentElement.style.setProperty('--gradient-start',theme.gradientStart);
document.documentElement.style.setProperty('--gradient-mid',theme.gradientMid);
document.documentElement.style.setProperty('--gradient-end',theme.gradientEnd);
var currentStep = steps[0];
const subscription = await FlowEngine.getSubscription(idSubscription);
const stepCB = steps[steps.length - 1].properties.find(x => x.property === "CallBackURl");
let callBackURl: string = stepCB !== undefined ? stepCB.value[0] : "this";
callBackURl = callBackURl === "" ? "this" : callBackURl;
console.log("Esta es la que llega");
console.log(callBackURl);  
const redirectUrl =
  (flow as WorkFlowModel).CallBackURl !== null ||
  (flow as WorkFlowModel).CallBackURl !== undefined
    ? (flow as WorkFlowModel).CallBackURl
    : (subscription as SubscriptionResponse).subscription.portalUrlClient;
const subscriptionId = (subscription as SubscriptionResponse).subscription.id;
const productId = (subscription as SubscriptionResponse).productSusbsctiption
  .idProduct;

const subJwt = await FlowEngine.getSubscriptionData(
  (subscription as SubscriptionResponse).subscription.subscription
);
const decodedJwt = await FlowEngine.decodeJwt(subJwt);
const subData = decodedJwt.payloadObj.secondaryKey;
console.log(subData);
let txGuid = "";
const tx = await FlowEngine.saveTransaction(
  subscriptionId,
  productId,
  flowReq.admStatus.id,
  idTx,
  txGuid
);
if (typeof tx !== "string") {
  console.log(tx);
  txGuid = tx.transactionService.transactionGuid;
}

const data = FlowEngine.buildData(
  idTx,
  idSubscription,
  email,
  phone,
  idFlow,
  (flow as WorkFlowModel).workflowSteps,
  txGuid,
  subData,
  productId,
  subscriptionId,
  theme
);
console.log(data);

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({
  routes,
  applications,
});

applications.forEach(registerApplication);
layoutEngine.activate();
loader.style.display = "none";
localStorage.removeItem("FlowResult");
navigateToUrl(flow.workflowSteps[0].specialKey);
//navigateToUrl("Results");

window.addEventListener("single-spa:before-routing-event", (evt) => {
  console.log("single-spa is about to mount/unmount applications!");
  console.log(evt);

  let cevt = evt as CustomEvent;

  const {
    originalEvent,
    newAppStatuses,
    appsByNewStatus,
    totalAppChanges,
    oldUrl,
    newUrl,
    navigationIsCanceled,
    cancelNavigation,
  } = cevt.detail;
  console.log(newUrl);
  if ((newUrl as string).includes("/Next")) {
    let nextIndex = flow.workflowSteps.findIndex((x) => x === currentStep);
    let nextS = flow.workflowSteps[nextIndex + 1];
    if (nextS != undefined) {
      currentStep = nextS;
      console.log("nav to ::: " + currentStep.specialKey);
      navigateToUrl(currentStep.specialKey);
    } else {
      //cancelNavigation();
      //cevt.detail.cancelNavigation();
      let payload = localStorage.getItem("FlowResult");
      console.log(payload);
      let token = FlowEngine.generateToken(payload);
      let url = callBackURl + "?result=" + token;
      console.log(url);
      navigateToUrl("Results");
      console.log("nav to ::: res");
      if(callBackURl !== "this"){
        console.log("redirect" + callBackURl);
        window.open(url, "_blank");
      }
    }
  } else if ((newUrl as string).includes("/Finish")) {
    //cancelNavigation();
    let payload = localStorage.getItem("FlowResult");
    let token = FlowEngine.generateToken(payload);
    let url = callBackURl + "?result=" + token;
    console.log(url);
    navigateToUrl("Results");
    console.log("nav to ::: res");
    if(callBackURl !== "this"){
      window.open(url, "_blank");
    }
  }
});
