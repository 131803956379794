import fetch from "node-fetch";
import { Step, Theme, WorkFlowModel } from "../models/workflowModel";
import { SubscriptionResponse } from "../models/suscription";
import { TxStatus } from "../models/status";
import { Transaction, TransactionService } from "../models/transaction";
import * as otpflow from "../assests/otpflow.json";
import { KJUR } from "jsrsasign";
import * as loaders from "../static/loaders";

export function getQueryParam(param: string): string {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
}

export async function getFlow(idFlow: string) {
  try {
    let response;
    if (idFlow !== "0") {
      response = await fetch(
        "https://apimarketplace.azure-api.net/marketplace/api/Workflow/" +
          idFlow,
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "db444575d27144d9876a0c5c0622fe7a",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = (await response.json()) as WorkFlowModel;
      console.log(result);
      return result;
    } else {
      response = JSON.stringify(otpflow);
      response = JSON.parse(response);
      console.log(response);
      return response;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log("error message:", error.message);
      return error.message;
    } else {
      console.log("unexpected error:", error);
      return "An unexpected error occurred";
    }
  }
}

export async function getFlowBySubscription(subscription: string) {
  try {
    let response;

    response = await fetch(
      "https://apimarketplace.azure-api.net/marketplace/api/Workflow/GetProductWorkflowBySubscription/" + subscription,
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "db444575d27144d9876a0c5c0622fe7a",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = (await response.json()) as WorkFlowModel;
    console.log(result);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.log("error message:", error.message);
      return error.message;
    } else {
      console.log("unexpected error:", error);
      return "An unexpected error occurred";
    }
  }
}

export async function getSubscription(aliasSub: string) {
  try {
    const response = await fetch(
      "https://apimarketplace.azure-api.net/marketplace/api/ProductSubscription/Get/" +
        aliasSub,
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "db444575d27144d9876a0c5c0622fe7a",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = (await response.json()) as SubscriptionResponse;
    console.log(result);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.log("error message:", error.message);
      return error.message;
    } else {
      console.log("unexpected error:", error);
      return "An unexpected error occurred";
    }
  }
}

export async function getSubscriptionData(subId: string) {
  try {
    const response = await fetch(
      "https://apimarketplace.azure-api.net/marketplace/api/Subscription/subscriptiondata?subscription=" +
        subId,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "db444575d27144d9876a0c5c0622fe7a",
        },        
      }
    );

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = (await response.json()) as string;
    console.log(result);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.log("error message:", error.message);
      return error.message;
    } else {
      console.log("unexpected error:", error);
      return "An unexpected error occurred";
    }
  }
}

export async function decodeJwt(JWT:string) {
  var parsedJwt = KJUR.jws.JWS.parse(JWT);
  return parsedJwt;
}

export async function getTxStatus() {
  try {
    const response = await fetch(
      "https://apimarketplace.azure-api.net/marketplace/api/Status/Get/TbtransactionServices",
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "db444575d27144d9876a0c5c0622fe7a",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = (await response.json()) as TxStatus;
    console.log(result);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.log("error message:", error.message);
      return error.message;
    } else {
      console.log("unexpected error:", error);
      return "An unexpected error occurred";
    }
  }
}

export async function saveTransaction(
  suscriptionId: number,
  productId: number,
  statusId: number,
  txId: string,
  txGuid: string
) {
  const tdata: TransactionService = {
    id: 0,
    transactionGuid: txGuid,
    susbcriptionId: suscriptionId,
    productId: productId,
    statusId: statusId,
    transaction: txId,
    registerDate: new Date().toISOString(),
    active: true,
  };
  const data: Transaction = {
    transactionService: tdata,
    error: null,
  };

  console.log("Transaction Data");
  console.log(data);

  try {
    const response = await fetch(
      "https://apimarketplace.azure-api.net/marketplace/api/TransactionServices",
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "db444575d27144d9876a0c5c0622fe7a",
        },
        body: JSON.stringify(data)
      }
    );

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    //console.log((await response.json()) as Transaction);
    return (await response.json()) as Transaction;
  } catch (error) {
    if (error instanceof Error) {
      console.log("error message:", error.message);
      return error.message;
    } else {
      console.log("unexpected error:", error);
      return "An unexpected error occurred";
    }
  }
}

export function buildData(
  txId: string,
  suscription: string,
  email: string,
  phone: string,
  idFlow: string,
  steps: Step[],
  txGuid: string,
  subKey: string,
  productId: number,
  subscriptionId: number,
  theme: Theme
) {
  const dataObj = {
    txId: txId,
    suscription: suscription,
    email: email,
    phone: phone,
    idFlow: idFlow,
    step: steps[0],
    steps: steps,
    txGuid: txGuid,
    productId: productId,
    subscriptionId: subscriptionId,
    subKey: subKey,
    theme: theme
  };

  var properties = JSON.stringify(dataObj);
  let data = {
    props: {
      properties: properties,
    },
    loaders: {
      mainContent: loaders.loaderMain,
    },
  };
  return data;
}

export function generateToken(payload) {
  // Header
  var oHeader = { alg: "HS256", typ: "JWT" };
  // Payload
  var oPayload: any = {};
  var tNow = KJUR.jws.IntDate.get("now");
  var tEnd = KJUR.jws.IntDate.get("now + 1day");
  oPayload.iss = "Binaria";
  oPayload.nbf = tNow;
  oPayload.iat = tNow;
  oPayload.exp = tEnd;
  oPayload.result = payload;
  // Sign JWT, password=616161
  var sHeader = JSON.stringify(oHeader);
  var sPayload = JSON.stringify(oPayload);
  var sJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, "616161");
  return sJWT;
}
